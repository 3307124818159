import React from 'react';
import PropTypes from 'prop-types';

import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../utils/behaviorSettings';

import styles from './index.module.scss';

const TwoColumns = ({
  main,
  sidebar = [],
  hasSuckedUpSidebar = false,
  behaviorSettings = null,
}) => (
  <div className={generateClassNameByBehaviorSettings(behaviorSettings)}>
    <div className="container">
      <div className={`two-columns-layout ${styles['two-columns-layout']}`}>
        <section className="main">
          {main.map((item, itemIndex) => (
            <React.Fragment key={itemIndex}>
              {/* eslint-disable-line */}
              {item}
            </React.Fragment>
          ))}
        </section>

        {sidebar.length > 0 && (
          <section className={`${hasSuckedUpSidebar ? 'sucked-up' : ''} sidebar`}>
            {sidebar.map((item, itemIndex) => (
              <React.Fragment key={itemIndex}>
                {/* eslint-disable-line */}
                {item}
              </React.Fragment>
            ))}
          </section>
        )}
      </div>
    </div>
  </div>
);

TwoColumns.propTypes = {
  main: PropTypes.arrayOf(PropTypes.node).isRequired,
  sidebar: PropTypes.arrayOf(PropTypes.node),
  hasSuckedUpSidebar: PropTypes.bool,
  behaviorSettings: behaviorSettingsProps,
};

export default TwoColumns;
