import React, { AllHTMLAttributes } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { KNOWLEDGE_HUB_URL } from '../../../utils/constants';
import { Link as Props } from '../../../types';

const rollbar = require('../../../utils/rollbar');

const defaultProps = {
  prefetch: false,
  replace: false,
  shallow: false,
  passHref: false,
  scroll: true,
  children: null,
};

/**
 * A little proxy around Next.js <Link> component
 * to make sure that only the correct `props` passed to <Link> component.
 */
const CustomLink = (componentProps: Props) => {
  const initialProps: Props = {
    ...defaultProps,
    ...componentProps,
  };

  const router = useRouter();
  const query = router.query || {};

  // Check if previewMode is enabled by previewToken value.
  const previewMode = !!query.previewToken?.length;

  const linkProps: AllHTMLAttributes<HTMLElement> = {};
  const allowedProps = [
    'key',
    'as',
    'prefetch',
    'replace',
    'shallow',
    'passHref',
    'scroll',
    'children',
    'onClick',
    'onTouchStart',
    'onMouseEnter',
    'locale',
    'href',
  ];

  // When the link comes empty due to some coding mistake fail gracefully.
  if (!initialProps.href) {
    if (rollbar && !previewMode) {
      rollbar.error(
        'Empty link. A void tag will be used instead. This probably due to some coding mistake that allows undefined links to be rendered.',
      );
    }

    return <a href="#" />;
  }

  // Workaround for reactive search. The lib doesn't work with CSR.
  // We render all links for Knowledge hub project without Link component.
  // For example: /knowledge-hub?filter_regions=["Asia"]
  // It is not a Primary solution. Look at getSearchParams / setSearchParams methods
  // in <ReactiveBase />. They can potentially help integrate ReactiveSearch
  // with Next.js routing.
  if (
    initialProps.as &&
    (initialProps.as.startsWith(`${KNOWLEDGE_HUB_URL}?`) ||
      initialProps.as === KNOWLEDGE_HUB_URL) &&
    typeof initialProps.children === 'object' &&
    initialProps.children.type === 'a'
  ) {
    return <a href={initialProps.as} {...initialProps.children.props} />;
  }

  // Put into link properties only values which are allowed for <Link> component.
  Object.keys(initialProps).forEach((key) => {
    if (key in initialProps && allowedProps.includes(key)) {
      // @ts-expect-error Allows this way of applying attributes here, it's expected.
      linkProps[key] = initialProps[key];
    }
  });

  // TODO: Update all components that use Link component to avoid <a>
  // child to be able to use a modern behaviour.
  return <Link {...(linkProps as Props)} legacyBehavior />;
};

// propTypes sits here only for compatibility purposes goal.
const propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefetch: PropTypes.bool,
  replace: PropTypes.bool,
  shallow: PropTypes.bool,
  passHref: PropTypes.bool,
  scroll: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export { propTypes };

export default CustomLink;
