import React from 'react';
import Link from '../../../01_atoms/Link';
import PropTypes from 'prop-types';
import RightThickChevron from '../../../../public/static/icons/navigation-chevron-right-white-thick.svg';

import styles from './index.module.scss';

class LinkTitle extends React.Component {
  constructor(props) {
    super(props);

    // if an onClick handler is defined, don't follow the normal link behavior.
    this.onClick = props.onClick
      ? (evt) => {
          evt.preventDefault();
          props.onClick(evt);
        }
      : () => {};

    this.state = {
      hovering: false,
    };
  }

  onKeyDown = (event) => {
    // Make sure that tabbing through the link doesn't fire the onClick.
    if (event.keyCode !== 9) {
      this.onClick(event);
    }
  };

  render() {
    const { url = null, label, hover = null, Tag = 'h3' } = this.props;

    const { hovering } = this.state;
    const LinkTitleCore = (
      <Tag className="link-title__title">
        {label}
        <RightThickChevron
          className={`link-title-chevron ${styles['link-title-chevron']} ${
            hover || hovering ? styles.hover : ''
          }`}
        />
      </Tag>
    );

    return url ? (
      <Link href={url}>
        <a
          className={`link-title ${styles['link-title']}`}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          onMouseEnter={() => this.setState({ hovering: true })}
          onMouseLeave={() => this.setState({ hovering: false })}
        >
          {LinkTitleCore}
        </a>
      </Link>
    ) : (
      <div
        className={`link-title ${styles['link-title']}`}
        onClick={this.onClick}
        onKeyDown={this.onKeyDown}
      >
        {LinkTitleCore}
      </div>
    );
  }
}

LinkTitle.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  hover: PropTypes.bool,
  url: PropTypes.string,
  Tag: PropTypes.oneOf(['h3', 'h4', 'h5']),
};

export default LinkTitle;
