import React from 'react';
import PropTypes from 'prop-types';

import { PTBreadcrumbs } from '../../../01_atoms/Breadcrumbs/prop-types.js';
import Breadcrumbs from '../../../01_atoms/Breadcrumbs';

import styles from './index.module.scss';

const BBBreadcrumbs = ({ breadcrumbs, inContainer = true }) => (
  <div className={`bb ${styles['bb-breadcrumbs']}`} data-component="bb-breadcrumbs">
    <div className={inContainer ? 'container' : null}>
      {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
    </div>
  </div>
);

BBBreadcrumbs.propTypes = {
  breadcrumbs: PTBreadcrumbs.isRequired,
  inContainer: PropTypes.bool,
};

export default BBBreadcrumbs;
