import PropTypes from 'prop-types';

const behaviorSettingsProps = PropTypes.shape({
  cw_background_color: PropTypes.shape({
    color: PropTypes.oneOf(['default', 'grey', 'yellow']),
  }),
  cw_outer_spacing: PropTypes.shape({
    top_spacing: PropTypes.oneOf(['standard', 'no_spacing']),
    bottom_spacing: PropTypes.oneOf(['standard', 'no_spacing']),
  }),
  cw_experiment_variant: PropTypes.shape({
    variant: PropTypes.oneOf(['a', 'b', 'c']),
  }),
});

export { behaviorSettingsProps };
