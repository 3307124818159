import React, { useState } from 'react';
import Link from '../../../01_atoms/Link';
import Button from '../../../01_atoms/Button';
import ItemWithImage from '../../../02_molecules/Item/ItemWithImage';
import Slider from '../../../01_atoms/Slider';
import { LinkChevron } from '../../../02_molecules/Link';
import { generateClassNameByBehaviorSettings } from '../../../../utils/behaviorSettings';
import { Link as LinkProps, LinkWithLabel, BehaviorSettings } from '../../../../types';

import styles from './index.module.scss';

type Props = {
  title?: string;
  link?: LinkWithLabel;
  button?: LinkWithLabel;
  background?: BBRelatedContentBackground;
  isMobileCollapsed?: boolean;
  items: BBRelatedContentItem[];
  behaviorSettings?: BehaviorSettings;
  uuid?: string;
};

type BBRelatedContentItem = {
  title?: string;
  description?: string;
  image?: string;
  nextLink?: LinkProps;
  type?: string;
  tags?: string[];
  timestamp: number;
};

type BBRelatedContentBackground = 'white' | 'biscuit';

const BBRelatedContent = ({
  title = '',
  items,
  link,
  button,
  background = 'white',
  isMobileCollapsed = true /* Make slider on mobile for items by default */,
  behaviorSettings,
  uuid,
}: Props) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const onAfterSlideChange = (index: number) => setActiveSlideIndex(index);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToScroll: 1,
    afterChange: onAfterSlideChange.bind(this),
  };

  const classes = [
    'bb',
    styles['bb-related-content'],
    styles[`bb-related-content--bg-color-${background}`],
    generateClassNameByBehaviorSettings(behaviorSettings),
  ];

  return (
    <div
      className={classes.join(' ')}
      id={uuid}
      data-component={'bb-related-content'}
      // Hide BBSidebarTableOfContents when it is nearby the element.
      data-toc="hide"
    >
      <div className="container">
        <div className={styles['bb-related-content__heading']}>
          {title && <h2>{title}</h2>}

          {link && link.label && link.nextLink && (
            <LinkChevron nextLink={link.nextLink} label={link.label} />
          )}
        </div>

        <div className={`slider ${isMobileCollapsed ? 'd-md-none' : 'd-none'}`}>
          <Slider {...sliderSettings}>
            {items.map((item) => (
              // @ts-expect-error ItemWithImage still not compatible with TS.
              <ItemWithImage key={item.title} className="bb-related-content--link" {...item} />
            ))}
          </Slider>
          <p className="counter">
            <strong>
              {activeSlideIndex + 1}/{items.length}
            </strong>
          </p>
        </div>

        <div
          className={`${styles['bb-related-content__items']} ${isMobileCollapsed ? styles['bb-related-content__items--collapsed'] : ''}`}
        >
          {items.map((item) => (
            <div className={styles['bb-related-content__item']} key={item.title}>
              {/* @ts-expect-error ItemWithImage still not compatible with TS. */}
              <ItemWithImage className={styles['bb-related-content--link']} {...item} />
            </div>
          ))}
        </div>

        {button && button.label && button.nextLink && (
          <div className={styles['bb-related-content__button-wrapper']}>
            <Link {...button.nextLink}>
              <Button
                href={button.nextLink.href}
                type="secondary"
                tag="a"
                isOutlined
                isMobileBlock
                className={styles['bb-related-content--button']}
              >
                {button.label}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default BBRelatedContent;
