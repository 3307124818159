import React from 'react';

import s from './index.module.scss';

type Props = {
  label: string;
  isUppercase?: boolean;
  textColor?: TagTextColor;
  borderColor?: TagTextBorderColor;
  backgroundColor?: TagTextBackgroundColor;
  title?: string;
};

type TagTextColor = 'white' | 'black-emergency';
type TagTextBorderColor = 'red';
type TagTextBackgroundColor = 'red' | 'black' | 'black-emergency';

const TagText = ({
  label,
  backgroundColor = 'black',
  textColor = 'white',
  borderColor,
  isUppercase = false,
  title,
}: Props) => (
  <mark
    className={`${s['tag-text']} ${s[`tag-text__background--${backgroundColor}`]} ${s[`tag-text__border--${borderColor}`]}`}
    data-component={'tag-text'}
  >
    <strong
      title={title}
      className={`${s['tag-text__label']} ${s[`tag-text__label--${textColor}`]} ${isUppercase ? s['tag-text__label--upper'] : ''}`}
    >
      {label}
    </strong>
  </mark>
);

export default TagText;
