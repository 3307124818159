import { arrayOf, bool, number, string, shape } from 'prop-types';
import { propTypes as picturePropTypes } from '../../../01_atoms/DefaultPicture';
import { propTypes as linkPropTypes } from '../../../01_atoms/Link';

export const PTItemWithImage = {
  title: string,
  description: string,
  image: shape(picturePropTypes),
  nextLink: shape(linkPropTypes).isRequired,
  type: string,
  tags: arrayOf(string),
  timestamp: number,
  isDesktopDescriptionHidden: bool,
  isMobileDescriptionHidden: bool,
  isDesktopTitleOverlaysImage: bool,
  isMobileTitleOverlaysImage: bool,
  isHovering: bool,
  isGradientOnImage: bool,
  className: string,
};
