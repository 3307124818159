/**
 * Takes a timestamp and formats it according to a specific date format. There is no single
 * locale that fulfills CW's requirements for date formatting.
 *
 * @param {Integer} timestamp      The UNIX-compliant timestamp, either in seconds or milliseconds.
 * @param {Boolean} inMilliseconds Whether or not the given timestamp is in seconds or milliseconds.
 * @returns {String}
 */
export const formattedDate = (timestamp, inMilliseconds = false) => {
  const datetime = new Date(timestamp * (inMilliseconds ? 1 : 1000));

  return `${datetime.toLocaleString('en-GB', { day: 'numeric', timeZone: 'Europe/London' })}
    ${datetime.toLocaleString('en-GB', { month: 'long', timeZone: 'Europe/London' })}
    ${datetime.toLocaleString('en-GB', { year: 'numeric', timeZone: 'Europe/London' })}`;
};
