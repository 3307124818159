import React from 'react';
import PropTypes from 'prop-types';

import {
  behaviorSettingsProps,
  generateClassNameByBehaviorSettings,
} from '../../../../../utils/behaviorSettings';

import styles from './index.module.scss';

/**
 * H1 and H5 are not available from admin UI.
 */
const BBHeading = ({
  Tag = 'h2',
  title,
  style = {},
  className = '',
  classNameWrapper = '',
  behaviorSettings = null,
  uuid = null,
}) => (
  <div
    className={`bb ${
      styles['bb-heading']
    } ${Tag} ${classNameWrapper} ${generateClassNameByBehaviorSettings(behaviorSettings)}`}
    id={uuid}
  >
    <div className="container">
      <Tag style={{ ...style }} className={className}>
        {title}
      </Tag>
    </div>
  </div>
);

BBHeading.propTypes = {
  Tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  title: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  className: PropTypes.string,
  classNameWrapper: PropTypes.string,
  behaviorSettings: behaviorSettingsProps,
  uuid: PropTypes.string,
};

export default BBHeading;
