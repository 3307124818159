import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick';
import { nanoid } from 'nanoid';

import styles from './index.module.scss';

class Slider extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.navClass) {
      this.state = {
        DOM_UID: `A${nanoid()}`,
      };
    } else {
      this.state = {
        DOM_UID: null,
      };
    }
  }

  toggleNavClass = () => {
    const { navClass = null } = this.props;
    const { DOM_UID } = this.state;

    // Can't use the reference, because it's a class component and there is no access to DOM node.
    // Can't use Slider prop, because there is no override for the slick-arrow element class.
    if (navClass) {
      [].forEach.call(document.querySelectorAll(`.${DOM_UID} .slick-arrow`), (element) => {
        if (!element.classList.contains(navClass)) {
          element.classList.add(navClass);
        }
      });
    }
  };

  render() {
    const { children, reference = () => {}, className = '', ...props } = this.props;
    const { DOM_UID } = this.state;

    return (
      <SlickSlider
        ref={reference}
        className={`${styles['slider']} ${className || ''} ${DOM_UID || ''}`}
        onReInit={this.toggleNavClass}
        {...props}
      >
        {children}
      </SlickSlider>
    );
  }
}

Slider.propTypes = {
  reference: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  navClass: PropTypes.string,
  className: PropTypes.string,
};

export default Slider;
