import React from 'react';
import Link from '../../../01_atoms/Link';
import { Link as LinkProps } from '../../../../types';

import NextChevron from '../../../../public/static/icons/navigation-chevron-right-white.svg';
import NextChevronThick from '../../../../public/static/icons/navigation-chevron-right-white-thick.svg';

import styles from './index.module.scss';

type Props = {
  nextLink?: LinkProps;
  label: string;
  size?: 'small' | 'normal';
  direction?: 'left' | 'right';
  isBold?: boolean;
};

const LinkChevron = ({
  nextLink,
  label,
  size = 'normal',
  direction = 'right',
  isBold = false,
}: Props) => (
  <>
    {nextLink && (
      <Link {...nextLink}>
        <a
          className={`link-chevron ${styles['link-chevron']} ${styles[`size-${size}`]} ${
            styles[direction]
          } ${isBold ? styles.bold : ''}`}
        >
          {direction === 'left' && (isBold ? <NextChevronThick /> : <NextChevron />)}

          {isBold ? <strong>{label}</strong> : label}

          {direction === 'right' && (isBold ? <NextChevronThick /> : <NextChevron />)}
        </a>
      </Link>
    )}
  </>
);

export default LinkChevron;
