import React from 'react';

import styles from './index.module.scss';
import Link from '../../../01_atoms/Link';
import { TagIcon, TagText } from '../../../01_atoms/Tag';
import { PTItemWithImage } from './prop-types';
import DefaultPicture from '../../../01_atoms/DefaultPicture';

import { formattedDate } from '../../../../utils/date-formatter.js';
import FormattedText from '../../../01_atoms/FormattedText';

const ItemWithImage = ({
  title,
  description,
  image,
  nextLink,
  type,
  tags = [],
  timestamp,
  isHovering = false,
  isGradientOnImage = false,
  isDesktopTitleOverlaysImage = false,
  isMobileTitleOverlaysImage = false,
  isDesktopDescriptionHidden = true,
  isMobileDescriptionHidden = false,
  className = '',
}) => (
  <div className={`item-with-image ${styles['item-with-image']} ${className}`}>
    <Link {...nextLink}>
      <a className={`main-link ${isHovering ? 'hover' : ''}`}>
        <div className="top" title={image ? image.alt : ''}>
          {image && <DefaultPicture loading="lazy" {...image} />}

          {isGradientOnImage && <div className="gradient" />}

          <div className="bottom-line">
            {(isDesktopTitleOverlaysImage || isMobileTitleOverlaysImage) &&
              !(isDesktopTitleOverlaysImage && isMobileTitleOverlaysImage) &&
              title && (
                <p
                  className={
                    'title' +
                    `${
                      isMobileTitleOverlaysImage && !isDesktopTitleOverlaysImage ? ' d-md-none' : ''
                    }` +
                    `${
                      !isMobileTitleOverlaysImage && isDesktopTitleOverlaysImage
                        ? ' d-none d-md-block'
                        : ''
                    }`
                  }
                >
                  {title}
                </p>
              )}

            {isDesktopTitleOverlaysImage && isMobileTitleOverlaysImage && title && (
              <h3 className="title">{title}</h3>
            )}

            {type && (
              <>
                <TagText label={type} />
                {typeof tags !== 'undefined' &&
                  tags.map((tag, i) => (
                    <TagIcon
                      key={`${tag}-${i}` /* eslint-disable-line react/no-array-index-key */}
                      type={tag}
                    />
                  ))}
              </>
            )}
          </div>
        </div>

        <div className="content">
          {timestamp && <mark className="date">{formattedDate(timestamp)}</mark>}

          {!(isDesktopTitleOverlaysImage && isMobileTitleOverlaysImage) && title && (
            <h3
              className={
                'title' +
                `${isDesktopTitleOverlaysImage ? ' d-md-none' : ''}` +
                `${isMobileTitleOverlaysImage ? ' d-none d-md-block' : ''}`
              }
            >
              {title}
            </h3>
          )}

          {description && (
            <FormattedText
              className={
                `description ${isDesktopDescriptionHidden ? 'hide-desktop' : ''}` +
                `${isMobileDescriptionHidden ? ' hide-mobile' : ''}`
              }
              text={description}
            />
          )}
        </div>
      </a>
    </Link>
  </div>
);

ItemWithImage.propTypes = PTItemWithImage;

export default ItemWithImage;
