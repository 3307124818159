import { arrayOf, bool, shape, string } from 'prop-types';

const PTBreadcrumbs = shape({
  links: arrayOf(
    shape({
      nextLink: shape({
        href: string,
        as: string,
      }).isRequired,
      label: string.isRequired,
    }),
  ).isRequired,
  color: string,
  showOnMobile: bool,
});

export { PTBreadcrumbs };
