import React from 'react';

import s from './index.module.scss';

type Props = {
  type: string;
};

const TagIcon = ({ type }: Props) => (
  <mark className={`${s['tag-icon']} ${s[`tag-icon__${type}`]}`}>&nbsp;</mark>
);

export default TagIcon;
