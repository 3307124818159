import React from 'react';
import Link from '../Link';

import { PTBreadcrumbs } from './prop-types.js';

import styles from './index.module.scss';

const Breadcrumbs = ({ links, color = 'turquoise', showOnMobile }) => {
  const classes = [styles.breadcrumbs, showOnMobile ? '' : styles['breadcrumbs--desktop']];

  // Only add a color class if it's defined in our styles.
  if (styles[`breadcrumbs--${color}`] !== undefined) {
    classes.push(styles[`breadcrumbs--${color}`]);
  }

  return (
    <div className={classes.join(' ')}>
      {links.map((link, i) =>
        i + 1 === links.length && !link.nextLink ? (
          link.label
        ) : (
          <React.Fragment
            key={`${link.label}-${i}` /* eslint-disable-line react/no-array-index-key */}
          >
            <Link {...link.nextLink}>
              <a
                title=""
                className={styles['breadcrumbs__link']}
                href={link.nextLink.url || link.nextLink.href}
              >
                {link.label}
              </a>
            </Link>
            <span className={styles['breadcrumbs__separator']}>/</span>
          </React.Fragment>
        ),
      )}
    </div>
  );
};

Breadcrumbs.propTypes = PTBreadcrumbs.isRequired;

export default Breadcrumbs;
